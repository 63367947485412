<template>
    <div class="sld_casual_question">
        <div class="top_header">
            <p class="header_title">{{ L['接待统计'] }}</p>
        </div>
        <div class="search-box">
            <el-form ref="form" :model="params" label-width="80px" size="small" @submit.prevent>
                <el-row>
                    <el-col :span="3">
                        <el-form-item label="客服类型">
                            <el-select size="small" v-model="params.type" placeholder="请选择客服类型" style="width: 100px;" :disabled="!isAdmin">
                                <el-option v-for="item in typeList" :key="item.value" :label="item.name" :value="item.value"/>
                            </el-select>
                        </el-form-item>
                    </el-col>
                    <el-col :span="4">
                        <el-form-item label="客服名称">
                            <el-input type="text" :placeholder="L['请输入客服名称']" v-model="params.keyword" clearable></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="6">
                        <el-form-item label="日期范围">
                            <el-date-picker v-model="dateRange" type="daterange" range-separator="-" :start-placeholder="L['开始日期']"
                                :end-placeholder="L['结束日期']" :disabled-date="disabledDate">
                            </el-date-picker>
                        </el-form-item>
                    </el-col>
                    <el-col :span="11">
                        <el-form-item label="" label-width="0px">
                            <button class="btn-search" @click="handleSearch">{{ L['查询'] }}</button>
                            <button class="btn-reset" @click="handleReset">{{ L['重置'] }}</button>
                            <button class="btn-export" @click="handleExport(1)">导出平均</button>
                            <button class="btn-export" style="background: #ff7800;" @click="handleExport(2)">导出</button>
                        </el-form-item>
                    </el-col>
                </el-row>
            </el-form>
        </div>
        <div class="tips-box">
            <el-alert title="统计规则：" type="info" show-icon>
                <div class="tips-list">
                    <div class="tips-item">平均回复率：会员发消息到客服的第一个回复为周期计算得到</div>
                    <div class="tips-item">平均在线时长：客服第一个回复时间到最后一个客服回复时间计算得到</div>
                    <div class="tips-item">平均接待人数：当天接待的人数</div>
                </div>
            </el-alert>
        </div>
        <el-table :data="statisticsList.list" :row-class-name="tableRowClassName">
            <el-table-column type="index" :label="L['序号']" width="60">
            </el-table-column>
            <el-table-column prop="type" :label="L['客服类型']" width="100">
                <template #default="scope">
                    <el-tag type="danger" size="small" v-if="scope.row.type === 2">电商</el-tag>
                    <el-tag type="success" size="small" v-if="scope.row.type === 3">平台</el-tag>
                </template>
            </el-table-column>
            <el-table-column prop="adminName" :label="L['客服名称']" width="200">
            </el-table-column>
            <el-table-column prop="averageReply" label="回复率（秒）" width="150">
                <template #default="scope">
                    <el-tooltip class="item" effect="dark" :content="scope.row.averageReply || '-' +'秒'" placement="top">
                        <div>{{ formatDate(scope.row.averageReply, 'second') || '-' }}</div>
                    </el-tooltip>
                </template>
            </el-table-column>
            <el-table-column prop="onlineTime" label="在线时长（分）" width="150">
                <template #default="scope">
                    <el-tooltip class="item" effect="dark" :content="scope.row.onlineTime || '-' +'分'" placement="top">
                        <div>{{ formatDate(scope.row.onlineTime, 'minute') || '-' }}</div>
                    </el-tooltip>
                </template>
            </el-table-column>
            <el-table-column prop="receiveNum" label="接待人数（人）" width="150">
            </el-table-column>
            <el-table-column prop="restNum" label="休息次数" width="100"></el-table-column>
            <el-table-column prop="busyNum" label="忙碌次数" width="100"></el-table-column>
            <el-table-column prop="restTime" label="休息时长（分）" width="150">
                <template #default="scope">
                    <el-tooltip class="item" effect="dark" :content="scope.row.restTime || '-' +'分'" placement="top">
                        <div>{{ formatDate(scope.row.restTime, 'minute') || '-' }}</div>
                    </el-tooltip>
                </template>
            </el-table-column>
            <el-table-column prop="busyTime" label="忙碌时长（分）" width="150">
                <template #default="scope">
                    <el-tooltip class="item" effect="dark" :content="scope.row.busyTime || '-' +'分'" placement="top">
                        <div>{{ formatDate(scope.row.busyTime, 'minute') || '-' }}</div>
                    </el-tooltip>
                </template>
            </el-table-column>
            <el-table-column prop="operatorTime" label="日期" width="150"></el-table-column>
            <el-table-column></el-table-column>
        </el-table>
        <div class="empty_ques" v-if="(!statisticsList.list.length > 0) && firstLoading">
            <img src="@/assets/goods/empty_data.png" alt="">
            <p>{{ L['暂无统计记录~'] }}</p>
        </div>
        <div class="sld_pagination">
            <el-pagination @prev-click="handlePrevCilickChange" @next-click="handleNextCilickChange"
                @current-change="handleCurrentChange" @size-change="handleSizeChange" 
                :currentPage="page.current" :page-size="page.pageSize" :page-sizes="[10, 20, 50, 100]"
                layout="sizes, prev, pager, next, jumper" :total="page.total" :hide-on-single-page="true">
            </el-pagination>
        </div>
    </div>
</template>

<script>
import { ref, getCurrentInstance, reactive, onMounted } from 'vue';
import dayjs from 'dayjs';
import { formatDate } from '@/utils/common';

export default {
    name: 'statistics',
    setup() {
        const role = ref(localStorage.role); // 2-电商 3-平台
        const isAdmin = ref(['admin', 'service'].includes(localStorage.adminName)); // 是否管理员
        const isService = ref(['p_service', 'm_service'].includes(localStorage.adminName)); // 是否组管理员
        const { proxy } = getCurrentInstance();
        const L = proxy.$getCurLanguage();
        const firstLoading = ref(false);
        const page = reactive({ // 页数
            current: '',
            pageSize: '',
            total: ''
        });
        const statisticsList = reactive({ list: [] }); // 列表数据
        const params = reactive({ 
            current: 1,
            pageSize: 10,
            keyword: '',
            type: '',
            startTime: '',
            endTime: '',
        });
        const typeList = ref([
            {name: '全部', value: ''},
            {name: '电商客服', value: '2'},
            {name: '平台客服', value: '3'},
        ]); // 客服类型列表
        const dateRange = ref([]); // 日期范围

        //获取转接记录
        const getList = () => {
            proxy.$get('im-web/v3/helpdesk/receive/statistics/list', params).then(res => {
                if (res.state == 200) {
                    statisticsList.list = res.data.list;
                    page.pageSize = res.data.pagination.pageSize;
                    page.total = res.data.pagination.total;
                    page.current = res.data.pagination.current;
                    firstLoading.value = true;
                }
            })
        }

        const init = () => {
            const date = dayjs().subtract(0, 'day');
            dateRange.value = [date.toDate(), date.toDate()];
            params.startTime = date.format('YYYY-MM-DD') + ' 00:00:00';
            params.endTime = date.format('YYYY-MM-DD') + ' 23:59:59';
            if (isService.value) {
                params.type = role.value;
            }
        }

        const disabledDate = (time) => {
            return time.getTime() > dayjs().subtract(1, 'day').toDate();
        }

        const handleClear = () => {
            params.startTime = '';
            params.endTime = '';
            getList();
        }

        const handleSearch = (e) => {
            console.log('dateRange.value: ', dateRange.value);
            if (dateRange.value) {
                params.startTime = dayjs(dateRange.value[0]).format('YYYY-MM-DD') + ' 00:00:00';
                params.endTime = dayjs(dateRange.value[1]).format('YYYY-MM-DD') + ' 23:59:59';
            }
            params.current = 1;
            getList();
        }

        const handleReset = (e) => {
            params.current = 1;
            params.keyword = '';
            init();
            getList();
        }

        // 导出
        const handleExport = (type) => {
            const url = type === 1 ? 'im-web/v3/helpdesk/receive/statistics/exportExcelAverage' : 'im-web/v3/helpdesk/receive/statistics/exportExcel'
            proxy.$get(url, params, 'blob').then(res => {
                let startTime = dayjs(params.startTime).format('YYYY-MM-DD');
                let endTime = dayjs(params.endTime).format('YYYY-MM-DD');

                const blob = new Blob([res], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
                const downloadElement = document.createElement('a');
                const href = window.URL.createObjectURL(blob); // 创建下载的链接
                downloadElement.href = href;
                downloadElement.download = `接待统计${type === 1 ? '_平均' : ''}_${ startTime }_${ endTime }.xlsx` // 下载后文件名
                document.body.appendChild(downloadElement);
                downloadElement.click(); // 点击下载
                document.body.removeChild(downloadElement); // 下载完成移除元素
                window.URL.revokeObjectURL(href);
            });
        }

        const handlePrevCilickChange = (e) => {
            params.current = e;
            getList();
        }

        const handleNextCilickChange = (e) => {
            params.current = e;
            getList();
        }

        const handleCurrentChange = (e) => {
            params.current = e;
            getList();
        }

        const handleSizeChange = (e) => {
            params.pageSize = e;
            getList();
        }

        onMounted(() => {
            init();
            getList();
        })


        return {
            formatDate,
            role,
            isAdmin,
            isService,
            disabledDate,
            typeList,
            params,
            dateRange,
            statisticsList,
            page,
            handleClear,
            handleSearch,
            handleReset,
            handleExport,
            handlePrevCilickChange,
            handleNextCilickChange,
            handleCurrentChange,
            handleSizeChange,
            confirm,
            firstLoading,
            L,
        }
    }
}
</script>

<style lang="scss">
.search-box {
    margin: 10px 0;
    .btn-search {
        width: 74px;
        height: 30px;
        background: #0E6FD7;
        border-radius: 3px;
        border: none;
        font-size: 16px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #FFFFFF;
        margin-left: 20px;
    }

    .btn-reset {
        width: 74px;
        height: 30px;
        background: #999;
        border-radius: 3px;
        border: none;
        font-size: 16px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #fff;
        margin-left: 20px;
    }

    .btn-export {
        width: 90px;
        height: 30px;
        background: #67c23a;
        border-radius: 3px;
        border: none;
        font-size: 16px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #FFFFFF;
        margin-left: 20px;
    }
}

.tips-box {
    margin-bottom: 20px;
}

.el-table th>.cell {
    text-align: center;
}

.el-table .cell {
    display: flex;
    justify-content: center;
}

.el-table__empty-block {
    display: none;
}


.sld_casual_question {
    .top_header {
        width: 100%;
        height: 59px;
        border-bottom: 1px solid #F6F6F6;
        display: flex;
        justify-content: space-between;
        align-items: center;

        .header_title {
            padding-left: 10px;
            border-left: 4px solid $colorMain;
            font-size: 16px;
            font-family: Microsoft YaHei;
            font-weight: bold;
            color: #333333;
        }

        button {
            color: #fff;
            padding: 11px;
            background-color: $colorMain;
            border-radius: 3px;
            border: none;
            margin-right: 15px;
            font-size: 14px;
            font-family: Microsoft YaHei;
        }
    }

    .top_bar {
        padding: 13px 0;
        background-color: #eee;
        display: flex;
        font-size: 15px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #333333;
        flex-shrink: 0;

        .number_title {
            padding: 0 80px;
        }

        .reply_title {
            width: 340px;
            text-align: center;
            /* padding: 0 180px; */
        }

        .sort_title {
            width: 200px;
            text-align: center;
            /* padding: 0 100px; */
        }

        .default_use_title {
            width: 270px;
            text-align: center;
            padding: 0 120px;
        }

        .operate_title {
            width: 310px;
            text-align: center;
            /* padding: 0 140px; */
        }

        .answer_title {
            width: 234px;
            text-align: center;
            /* padding: 0 100px; */
        }
    }

    .container {
        height: 710px;

        .con_item {
            width: 99%;
            display: flex;
            height: 50px;
            align-items: center;
            border-bottom: 1px solid #f2f2f2;

            .number {
                width: 190px;
                text-align: center;
            }

            .sort {
                text-align: center;
                width: 200px;
            }

            .default_use {
                padding: 0 117px;
            }

            .operate {
                width: 310px;
                display: flex;
                justify-content: center;


            }

            .content {
                width: 340px;
                text-align: center;
                word-break: break-all;
                overflow: hidden;
                text-overflow: ellipsis;
                display: -webkit-box;
                -webkit-line-clamp: 2;
                -webkit-box-orient: vertical;
                padding-left: 10px;
                padding-right: 10px;
            }

            .answer {
                width: 240px;
                text-align: center;
                word-break: break-all;
                overflow: hidden;
                text-overflow: ellipsis;
                display: -webkit-box;
                -webkit-line-clamp: 2;
                -webkit-box-orient: vertical;
                padding-left: 10px;
                padding-right: 10px;
            }

        }
    }

    .sld_pagination {
        margin-top: 20px;
    }

    .el-dialog {
        border-radius: 10px;
    }

    .el-dialog__header {
        background: #409Eff;
        border-top-right-radius: 9px;
        border-top-left-radius: 9px;
        height: 40px;
        padding: 11px 20px 10px;
    }

    .el-dialog__headerbtn {
        top: 13px;
    }

    .el-dialog__title {
        color: #fff;
    }

    .el-dialog__headerbtn .el-dialog__close {
        color: #fff;
    }

    .el-button {
        padding: 7px 19px;
    }

    .dialog-modal {
        width: 600px !important;
        @media screen and (max-width: 640px) {
            width: 365px !important;
        }
    }

    #popTable {
        margin: 0 auto;
        border-spacing: 10px;
        width: 100%;

        .title {
            text-align: right;
        }

        .tip {
            color: #C0C4CC;
        }

        i {
            color: #e2231a;
            font-style: normal;
        }

        .message {
            font-size: 12px;
            line-height: 2;
            padding-top: 4px;
            color: #e2231a;
            font-family: Microsoft YaHei;
        }

        td {
            max-width: 284px;

            &.title {
                padding-top: 9px;
                min-width: 80px;
            }
        }
    }
}

.empty_ques {
    display: flex;
    align-items: center;
    justify-content: center;
    padding-top: 200px;

    p {
        color: #999999;
        font-size: 14px;
        margin-left: 10px;
    }
}
</style>